// We need to have this manual override in place to allow for a "Play All" display.

module.exports = {
  playAllOverrides: [
    {
      tournamentId: '106273298442359221',
      stages: ['regular_season']
    },
    {
      tournamentId: '107418086627198298',
      stages: ['regular_season']
    }
  ],
  tier1LeagueIds: [
    '98767991302996019', // LEC
    '98767991314006698', // LPL
    '98767991310872058', // LCK
    '113476371197627891', // LCP
    '113475149040947852', // LTA
    '113470291645289904', // LTA North
    '113475181634818701', // LTA South
    '113464388705111224', // First Stand
    '98767991325878492', // MSI
    '98767975604431411' // Worlds
  ]
}
