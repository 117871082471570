import { Component } from 'lib'
import Accordion from 'Component/Accordion/Accordion'
import Dropdown from 'Component/Dropdown/Dropdown'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'

const statsOptions = {
  sidebar: 'Sidebar',
  theater: 'Theater'
}

const providerNames = {
  youtube: 'YouTube',
  twitch: 'Twitch',
  openrec: 'OPENREC',
  nimotv: 'NimoTV',
  mildom: 'Mildom',
  afreecatv: 'Soop',
  trovo: 'Trovo'
}

class SettingsGeneral extends Component {
  translate (key) {
    const localeKey = `settings.general.${key}`

    return locale.translate(localeKey)
  }

  renderToggle (key, onString, offString) {
    const { watchSettings } = this.props
    const isEnabled = (key) => watchSettings[key] === onString
    const classes = util.classNames('toggle', isEnabled(key) && 'selected')

    const onClick = () => {
      const setting = this.props.watchSettings[key]
      const value = setting === onString ? offString : onString

      this.props.onSettingsChange({ [key]: value })
    }

    return (
      <div class={ classes } onClick={ onClick }>
        <div class="bubble"/>
      </div>
    )
  }

  getVideoProviders () {
    return Object.keys(providerNames).map((slug) => ({
      label: providerNames[slug],
      value: slug,
      icon: slug
    }))
  }

  getStatsLayouts () {
    return Object.keys(statsOptions).map((slug) => ({
      label: locale.translate(`watch.layoutSelection.${slug}`),
      value: slug,
      icon: slug
    }))
  }

  renderVideoProviderDropdown (key) {
    const { watchSettings } = this.props

    const value = watchSettings.defaultVideoProvider

    const options = this.getVideoProviders()

    const anyLabel = locale.translate('watch.providerSelection')
    const emptySelect = {
      label: anyLabel.charAt(0).toUpperCase() + anyLabel.toLowerCase().slice(1),
      value: 'any',
      icon: ''
    }

    options.unshift(emptySelect)

    return (
      <div class="videoProvider">
        <Dropdown
          class="dropdown"
          options={ options }
          onSelect={ (value) => this.props.onSettingsChange({ [key]: value }) }
          value={ value }
        />
      </div>
    )
  }

  renderStatsLayoutDropdown (key) {
    const { watchSettings } = this.props

    return (
      <div class="statsLayout">
        <Dropdown
          class="dropdown"
          options={ this.getStatsLayouts() }
          onSelect={ (value) => this.props.onSettingsChange({ [key]: value }) }
          value={ watchSettings.statsLayout }
        />
      </div>
    )
  }

  renderJumpToStart () {
    const classes = util.classNames('option', 'jumpToStart')

    return (
      <div class={ classes }>
        <label class="label">{ this.translate('jumpToStart', false) }</label>
        { this.renderToggle('jumpToStart', 'always', 'never') }
      </div>
    )
  }

  renderStatsLayout () {
    return (
      <div class="option">
        <label class="label">{ this.translate('statsLayout', false) }</label>
        { this.renderStatsLayoutDropdown('statsLayout') }
      </div>
    )
  }

  renderVideoProvider () {
    return (
      <div class="option">
        <label class="label">
          { this.translate('defaultVideoProvider', false) }
        </label>
        { this.renderVideoProviderDropdown('defaultVideoProvider') }
      </div>
    )
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <Accordion title={ this.translate('general') } open>
          <div class="general">
            { this.renderJumpToStart() }
            { this.renderStatsLayout() }
            { this.renderVideoProvider() }
          </div>
        </Accordion>
      </div>
    )
  }
}

export default SettingsGeneral
