import { Component, Logger, store } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import contentstack from 'Common/Service/Contentstack/Contentstack'
import relApi from 'Common/Service/RelApi/RelApi'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import LeaguesDropdown from 'Component/Navigation/TopMenu/LeaguesDropdown/LeaguesDropdown'
import LeaguesDrawer from 'Component/Navigation/TopMenu/LeaguesDrawer/LeaguesDrawer'
import EventsDropdown from 'Component/EventsDropdown/EventsDropdown'

const riotBarMobileQuery = window.matchMedia('(max-width: 1023px)')

class TopMenu extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.hl = locale.get()
  }

  componentDidMount () {
    const riotBarIsMobile = riotBarMobileQuery.matches

    store.set('riotBar.isMobile', riotBarIsMobile)

    this.setState({
      riotBarIsMobile: riotBarIsMobile,
      leaguesMenuIsActive: false,
      leaguesSubnavItems: null,
      leagues: null,
      activeLeagueIndex: -1
    })

    riotBar.setup()
    this.addListeners()
  }

  componentWillUnmount () {
    this.removeListeners()
  }

  addListeners () {
    store.onChange(
      'leaguesMenu.isActive',
      this.leaguesMenuIsActiveListener = (isActive) => {
        this.setState({ leaguesMenuIsActive: isActive })
        if (isActive && !this.state.leaguesSubnavItems) {
          this.fetchLeaguesSubnavItems()
          this.fetchLeagues()
        }
      }
    )

    riotBarMobileQuery.addListener((query) => {
      const riotBarIsMobile = query.matches

      this.setState({ riotBarIsMobile })
      store.set('riotBar.isMobile', riotBarIsMobile)
      store.set('leaguesMenu.isActive', false)
    })
  }

  removeListeners () {
    store.removeListener(
      'leaguesMenu.isActive',
      this.leaguesMenuIsActiveListener
    )
  }

  fetchLeaguesSubnavItems () {
    return contentstack
      .fetchLeaguesSubnavItems()
      .then((entries) => {
        this.setState({
          leaguesSubnavItems: this.sortLeaguesSubnavItems(entries)
        })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  fetchLeagues () {
    return relApi
      .fetchLeagues()
      .then((json) => {
        const leagues = json.leagues

        this.setState({ leagues })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  sortLeaguesSubnavItems (leaguesSubnavItems) {
    const orderedLeagues = leaguesSubnavItems.map((item) => {
      const homeLocales = util.getDeepValue(item, 'home_locales')

      item.order
        = homeLocales && homeLocales.indexOf(this.hl) !== -1 ? 0 : item.order
      return item
    })

    return util.sortListByPropertyValue(orderedLeagues, 'order')
  }

  getLeagueTitleWithRegion (leaguesSubnavItem, leagues) {
    const leagueTitle = leaguesSubnavItem.league[0].title
    const selectedLeague = util.getObjectFromKey('name', leagueTitle, leagues)
    const region
      = selectedLeague && selectedLeague.hasOwnProperty('region')
        ? selectedLeague.region
        : ''

    return region ? leagueTitle + ' (' + region + ')' : leagueTitle
  }

  setActiveLeague (index) {
    this.setState({ activeLeagueIndex: index })
  }

  renderLeaguesDrawer (props) {
    return (
      <div class="navmenu-drawer">
        <LeaguesDrawer { ...props } level={ 1 }/>
        { this.state.activeLeagueIndex > -1
          && <LeaguesDrawer { ...props } level={ 2 }/>
        }
      </div>
    )
  }

  renderLeaguesDropdown (props) {
    return <LeaguesDropdown { ...props }/>
  }

  renderLeaguesMenu () {
    const { riotBarIsMobile, leaguesSubnavItems, leagues, error } = this.state

    const props = {
      items: leaguesSubnavItems,
      leagues: leagues,
      activeLeagueIndex: this.state.activeLeagueIndex,
      setActiveLeague: (index) => this.setActiveLeague(index),
      getLeagueTitleWithRegion: this.getLeagueTitleWithRegion,
      error: error
    }

    return riotBarIsMobile
      ? this.renderLeaguesDrawer(props)
      : this.renderLeaguesDropdown(props)
  }

  renderError (error) {
    return (
      <InformBubble theme="error" icon="error" style={ { zIndex: 10001 } }>
        { error.message }
      </InformBubble>
    )
  }

  renderLeaguesMenuOrError () {
    const { error } = this.state

    return error ? this.renderError(error) : this.renderLeaguesMenu()
  }

  render () {
    // wrapper RiotBar gets inserted into
    // NOTE: id="top-menu" is required by riotbar. To update it go into Contentstack

    return (
      <div class={ this.constructor.name }>
        <nav id="top-menu"/>
        <EventsDropdown/>
        { this.state.leaguesMenuIsActive && this.renderLeaguesMenuOrError() }
      </div>
    )
  }
}

export default TopMenu
